import React from "react"

import Paragraph from "decentraland-gatsby/dist/components/Text/Paragraph"
import { Link } from "decentraland-gatsby/dist/plugins/intl"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import unescape from "lodash/unescape"

import {
  isWebpSupported,
  useImageOptimization,
} from "../../../hooks/contentful"
import { BlogPostAttributes } from "../../../modules/formats"
import LoadingText from "../../Loading/LoadingText"

import "./MainPostCard.css"

export default React.memo(function MainPostCard(props: {
  post?: BlogPostAttributes
  loading?: boolean
}) {
  const { post, loading } = props
  const { jpg, webp } = useImageOptimization(props.post?.image)
  return (
    <div className={TokenList.join(["main-post__card", loading && "loading"])}>
      <Link href={post?.url}>
        <div
          className="main-post__image"
          style={
            !loading && jpg
              ? {
                  backgroundImage: `url(${(isWebpSupported() && webp) || jpg})`,
                }
              : {}
          }
        ></div>
      </Link>

      {loading && (
        <div className="main-post__info">
          <div className="main-post__loading-header">
            <LoadingText type="span" size="medium" />
            <LoadingText type="span" size="medium" />
          </div>

          <LoadingText type="h2" size="large" lines={2} />

          <LoadingText type="p" size="full" lines={6} />
        </div>
      )}

      {!loading && post && (
        <div className="main-post__info">
          <span className="main-post__date">{post.publishedDate}</span>

          <span className="main-post__category">
            <a href={post?.categoryUrl}>{post?.categoryTitle}</a>
          </span>
          <h2>
            <Link href={post.url}>{unescape(post.title)}</Link>
          </h2>
          <Paragraph>{unescape(post.description)}</Paragraph>
        </div>
      )}
    </div>
  )
})
