import React from "react"

import { useMediaQuery } from "react-responsive"

import { BlogPostAttributes } from "../../modules/formats"
import MainPostCard from "../Blog/MainPostCard/MainPostCard"
import PostCard from "../Blog/PostCard/PostCard"

export type PostListProps = {
  blogPosts: BlogPostAttributes[]
  loading: boolean
  className?: string
  hasMainPost?: boolean
}

export default function PostList(props: PostListProps) {
  const { blogPosts, loading, className, hasMainPost } = props
  const isBigScreen = useMediaQuery({ minWidth: 1096 })

  return (
    <div className={className}>
      {loading &&
        Array.from(Array(hasMainPost ? 7 : 6), (_, index) => {
          if (hasMainPost && index === 0) {
            return <MainPostCard key={index} loading />
          }
          return <PostCard key={index} loading />
        })}
      {!loading &&
        blogPosts.map((post, index) => {
          if (hasMainPost && index === 0 && isBigScreen) {
            return <MainPostCard key={post.id} post={post} />
          }
          return <PostCard key={post.id} post={post} />
        })}
    </div>
  )
}
