import React, { useState } from "react"

import Head from "decentraland-gatsby/dist/components/Head/Head"
import useAsyncMemo from "decentraland-gatsby/dist/hooks/useAsyncMemo"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import { Button } from "decentraland-ui/dist/components/Button/Button"
import { Container } from "decentraland-ui/dist/components/Container/Container"

import { LandingFooterV2 } from "../components/Landing/LandingFooterV2"
import BlogNavigation from "../components/Layout/BlogNavigation"
import Layout from "../components/Layout/Layout"
import PostList from "../components/Layout/PostList"
import backgroundDclImage from "../images/background-v3@1x.jpg"
import { getBlogCategories, getBlogposts } from "../modules/contentful"
import { BlogPostAttributes, createBlogPostList } from "../modules/formats"

import "./blog.css"

const POST_PER_PAGE = 6
const POST_PER_PAGE_INITIAL = 7

export default function BlogPage(props: any) {
  const l = useFormatMessage()

  const [fetchOptions, setFetchOptions] = useState({
    offset: 0,
    limit: POST_PER_PAGE_INITIAL,
  })
  const [blogPosts, setBlogPosts] = useState<BlogPostAttributes[]>([])
  const [hasLoadMore, setHasLoadMore] = useState(true)

  const [categories] = useAsyncMemo(getBlogCategories)

  const [, blogpostState] = useAsyncMemo(
    async () => {
      if (!categories) {
        return false
      }
      const blogpostContent = await getBlogposts(fetchOptions)
      const blogpostCurated = await createBlogPostList(
        blogpostContent,
        categories
      )

      setBlogPosts((prev) => {
        const newBlogPost = [...prev, ...blogpostCurated]
        if (newBlogPost.length >= blogpostContent.total) {
          setHasLoadMore(false)
        }
        return newBlogPost
      })
    },
    [categories, fetchOptions],
    {
      callWithTruthyDeps: true,
    }
  )

  return (
    <Layout
      {...props}
      isFullscreen
      isOverlay
      hideFooter={false}
      className="layout__blog"
      activePage="learn"
      hideSocialLinks
    >
      <Head
        title={l("page.blog.title") || ""}
        description={l("page.blog.description") || ""}
        image={backgroundDclImage}
      />
      <BlogNavigation active={"all_articles"} />
      <Container>
        <PostList
          blogPosts={blogPosts}
          loading={!blogpostState.loaded}
          hasMainPost
          className="blog-post__wrapper"
        />
        {hasLoadMore && (
          <div className="blog-post__load-more">
            <Button
              onClick={() => {
                setFetchOptions((prev) => ({
                  limit: POST_PER_PAGE,
                  offset: prev.offset
                    ? prev.offset + POST_PER_PAGE
                    : POST_PER_PAGE_INITIAL,
                }))
              }}
            >
              {l("page.blog.load_more")}
            </Button>
          </div>
        )}
      </Container>
      <LandingFooterV2 />
    </Layout>
  )
}
